.rbc-agenda-view {
  box-shadow: 0 12px 20px -10px rgba(218, 223, 225, 0.3),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(218, 223, 225, 0.2) !important;
  border-radius: 6px;
  border: none !important;
}

.rbc-agenda-view table {
  border: none !important;
}

.rbc-agenda-view table thead {
  border: none !important;
  background: #e5a82e;
}

.rbc-agenda-view table tbody > tr > td {
  vertical-align: auto;
}

.rbc-agenda-view table thead tr th {
  color: #ffffff;
  font-size: 1.3em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
  line-height: 1.5em;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 1%;
}

.rbc-agenda-view table tbody > tr > td {
  font-size: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
}

.rbc-row-content {
  min-height: auto !important;
}

.rbc-month-row,
.rbc-month-row + .rbc-month-row {
  min-height: 90px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
